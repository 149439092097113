<template>
	<div class="video-play-wrapper" style="background-color: rgba(0, 0, 0, 1) !important">
		<div style="background-color: rgba(0, 0, 0, 1) !important">
			<MyHeader />
			<VideoPlay ref="video" :sources="sources" :is-load="true" :bg-img="bgImg" v-if="hasVideo">
				<template #footer>
					<Info :info="infoArr" @addCart="addCart" />
				</template>
				<template #right>
					<RightControl v-if="shopInfo.list.showPlay == 1" :prt-uuid="uuid" :collCount.sync="collCount"
						:is-like.sync="isLike" />
				</template>
			</VideoPlay>
			<div v-else-if="!hasVideo" class="video-none">
				<div class="no-name">暂无视频</div>
				<div style="position: absolute; right: 22px; top: 240px">
					<RightControl v-if="shopInfo.list.showPlay == 1" :prt-uuid="uuid" :is-like.sync="isLike" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VideoPlay from "@/components/videoPlay/index.vue";
	import MyHeader from "./components/Header.vue";
	import RightControl from "./components/RightControl.vue";
	import Info from "./components/Info.vue";
	import {
		clickPrtAddOne,
		getFileList,
		getProOne,
		addToCart
	} from "@/api";
	import {
		setPageTitle
	} from "@/utils";
	import {
		Toast
	} from "vant";
	import {
		wxConfig
	} from "@/api/wxApi";
	import _ from "lodash";
	export default {
		components: {
			VideoPlay,
			MyHeader,
			RightControl,
			Info,
		},
		data() {
			return {
				hasVideo: true,
				activeIndex: 0,
				list: [],
				uuid: "",
				isLike: 0,
				prtVideoList: [],
				videoPictureList: [],
				collCount: 0,
				sources: '',
				bgImg: '',
				infoArr: [],
				title:''
			};
		},
		computed: {
			shopInfo() {
				return this.$store.state.shopInfo
			},
		},
		async created() {
			this.uuid = "";
			const query = this.$route.query;
			let title = query?.title;
			this.title = query?.title
			if (!title) {
				title = query?.names;
			}
			if (title) {
				setPageTitle(title);
			}
			this.$forceUpdate();
			const uuid = query?.uuid;
			const shopUuid = query?.shopUuid;
			this.uuid = uuid;
			// shopUuid存在代表是从扫码进来的 需要在此页面处理视频数据 如果不存在代表从视频按钮进来 数据已在上一个页面处理
			if (shopUuid) {
				localStorage.setItem("videoArr", "");
				await this.handleVideoPlay();
			}
			if (localStorage.getItem("videoArr")) {
				this.list = JSON.parse(localStorage.getItem("videoArr"));
				this.hasVideo = (this.list && this.list.length > 0) || this.list[0]?.prtVideoUrl;
				this.isLike = this.list[0]?.isLike;
				this.sources = this.list[0]?.sources;
				this.bgImg = this.list[0]?.bgImg;
				this.infoArr = this.list[0]?.infoArr;
				wxConfig(1,{name:this.title ,url:this.bgImg} );
			} else {
				this.hasVideo = false;
			}
			
		},
		deactivated() {
			this.$destroy(this);
		},
		methods: {
			// 生成视频左下角信息
			getVideoInfo() {
				let result = [];
				if (this.info.isName == 1) {
					result.push({
						label: "名称",
						value: this.info.prtName,
						state: this.info.isName,
					});
				}
				if (this.info.isType == 1) {
					result.push({
						label: "类别",
						value: this.info.prtType,
						state: this.info.isType,
					});
				}
				if (this.info.isCode == 1) {
					result.push({
						label: "货号",
						value: this.info.prtCode,
						state: this.info.isCode,
					});
				}
				if (this.info.isChiCun == 1) {
					result.push({
						label: "体积",
						value: `${this.info.prtLong}cm*${this.info.prtWide}cm*${this.info.prtHigh}cm`,
						state: this.info.isChiCun,
					});
				}
				if (this.info.isSpecs == 1) {
					result.push({
						label: "规格",
						value: this.info.prtSpecs,
						state: this.info.isSpecs,
					});
				}
				if (this.info.isContent == 1) {
					result.push({
						label: "含量",
						value: this.info.prtContent,
						state: this.info.isContent,
					});
				}
				if (this.info.isHairNum == 1) {
					result.push({
						label: "发数",
						value: this.info.prtHairNum + "发",
						state: this.info.isHairNum,
					});
				}
				if (this.info.isInch == 1) {
					result.push({
						label: "寸数",
						value: this.info.prtInch + "寸",
						state: this.info.isInch,
					});
				}
				if (this.info.isWeight == 1) {
					result.push({
						label: "重量",
						value: this.info.prtWeight + "KG",
						state: this.info.isWeight,
					});
				}
				if (this.info.isEffect == 1) {
					result.push({
						label: "效果",
						value: this.info.prtEffect,
						state: this.info.isEffect,
					});
				}
				return result;
			},
			addCart() {
				if (this.list) {
					let param = {
						num: 1,
						productId: this.list[0].uuid,
						shopUuid: this.$store.state.shopUuid,
						userId: this.$store.state.userInfo.uuid, // 分类名
					};
					addToCart(param).then((res) => {
						if (res.state == 100) {
							Toast.success("成功加入购物车");
						}
					});
				}
			},
			onChange(index) {
				this.$refs.video.forEach((item) => {
					item.resetVideo();
				});
				this.list[index].isLoad = true;
			},
			// 组装信息
			async handleVideoPlay() {
				this.prtVideoList = await this.getProOne();
				if(!this.prtVideoList &&  !this.info.prtVideoUrl){
					return
				}
				if ((!this.prtVideoList && this.prtVideoList.length < 1) && !this.info.prtVideoUrl) {
					return;
				}
				// 增加视频点击量
				await clickPrtAddOne({
					uuid: this.uuid
				});
				if (this.prtVideoList && this.prtVideoList.length > 0) {
					const videoArr = this.prtVideoList.map((item, index) => {
						const oneVideo = {
							id: index,
							bgImg: this.info.isPic == 1 ?
								this.videoPictureList[0]?.filePath ?? "" : "",
							isLoad: index == 0,
							sources: item.filePath || "",
							infoArr: this.getVideoInfo(),
							uuid: this.uuid,
							isLike: this.info.isLike,
							prtVideoUrl: this.info?.prtVideoUrl
						};
						this.collCount = this.info.collCount
						
						this.bgImg = oneVideo.bgImg;
						wxConfig(1,{name:this.title ,url:this.bgImg} );
						return oneVideo;
					});
					localStorage.setItem("videoArr", JSON.stringify(videoArr));
				} else if (this.info.prtVideoUrl) {
					const videoArr = [{
						id: 0,
						bgImg: this.info.isPic == 1 ?
							this.videoPictureList[0]?.filePath ?? "" : "",
						isLoad: 0,
						sources: this.info.prtVideoUrl,
						infoArr: this.getVideoInfo(),
						uuid: this.uuid,
						isLike: this.info.isLike,
						prtVideoUrl: this.info?.prtVideoUrl
					}]
					this.collCount = this.info.collCount
					this.bgImg = videoArr.bgImg;
					wxConfig(1,{name:this.title ,url:this.bgImg} );
					localStorage.setItem("videoArr", JSON.stringify(videoArr));
				};
			},

			// 获取视频
			getFileList() {
				const params = {
					page: 0,
					pageSize: 0,
					typeName: "prtVideo",
					parentUuid: this.uuid,
				};
				return getFileList(params).then((res) => {
					if (res.state == 100) {
						return res.items;
					}
				});
			},

			// 获取单个产品详情
			async getProOne() {
				const data = {
					uuid: this.uuid,
				};
				return getProOne(data).then((res) => {
					if (res.state == 100) {
						this.info = res.items;
						this.collCount = this.info.collCount
						this.title = this.info?.prtName
						setPageTitle(this.info?.prtName);
						const picList = this.info.picList || [];
						if (!picList || picList.length < 1) {
							return;
						}
						this.$store.commit("SET_PRODUCT_INFO", this.info);
						this.$store.commit("setProduct", 1);
						
						this.detailPic = picList.filter((item) => item.typeName == "prtInfo");
						this.videoPictureList = picList.filter(
							(o) => o.typeName == "videoPicture"
						);
						return this.getFileList();
					}
				});
				 
			},
		},
	};
</script>

<style lang="less" scoped>
	.video-play-wrapper {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.my-swipe {
			height: 100%;
		}

		/deep/.van-swipe__indicators {
			display: none; //去掉指示器
		}
	}

	.video-none {
		height: 200px;
		width: 100vw;
		text-align: center;
		font-size: 18px;

		.no-name {
			font-size: 36px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #f7f7f7;
			opacity: 0.3;
			position: absolute;
			top: 300px;
			text-align: center;
			width: 100%;
		}
	}
</style>