<template>
	<div>
		<!-- $store.state.deLogoSquare -->
		<!-- <van-image fit="cover" class="home" :src="$store.state.deLogoSquare" alt="" @click.stop="$router.push('/home?shopUuid='+$store.state.shopUuid)" /> -->
		<img class="home" src="~@/assets/img-v3/new-playVideo/guanwang.png" alt=""
			@click.stop="$router.push('/home?shopUuid='+$store.state.shopUuid)" />
		<p @click.stop="$router.push('/home?shopUuid='+$store.state.shopUuid)">首页</p>
		<img v-if="isLike==1" class="heart" src="~@/assets/img-v3/new-playVideo/heart-fill.png" alt=""
			@click.stop="handleLike('reCollection')">
		<img v-else class="heart" src="~@/assets/img-v3/new-playVideo/heart-fill.png" alt=""
			@click.stop="handleLike('collection')">
		<p>{{(collCountNum < 10 ?('0'+(collCountNum + 1)) :collCountNum)}}</p>
		<img class="detail" src="~@/assets/img-v3/new-playVideo/detail.png" alt="" @click.stop="goProDetail">
		<p @click.stop="goProDetail">详情</p>
	</div>
</template>

<script>
	import {
		reCollection,
		collection
	} from '@/api'
	export default {
		props: {
			prtUuid: {
				type: String,
				default: ''
			},
			isLike: {
				type: Number,
				default: 0 // 0不喜欢，1喜欢
			},
			collCount:{
				type: Number,
				default: function () {
					return 0
				} // 喜欢次数
			}
		},
		data() {
			return {
				hasCollCount: 0
			}
		},
		computed:{
			collCountNum(){
				return this.collCount + this.hasCollCount
			}
		},
		methods: {
			goProDetail() {
				this.$router.push({
					name: 'ProductDetails',
					query: {
						uuid: this.prtUuid
					}
				})
			},

			async handleLike(api) {
				if (api == 'reCollection') {
					await reCollection({
						likColl: 1,
						prtUuid: this.prtUuid
					})
					this.$emit('update:isLike', 0)
				} else {
					await collection({
						likColl: 1,
						prtUuid: this.prtUuid
					}).then(res =>{
						this.hasCollCount = 1
					})
					this.$emit('update:isLike', 1)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	p {
		color: #fff;
		text-align: center;
		font-size: 12px;
		margin-bottom: 24px;
	}

	.heart,
	.detail {
		width: 30px;
		height: 30px;
		margin-bottom: 4px;
	}

	.home {
		width: 30px;
		height: 30px;
		margin-bottom: 4px;
	}
</style>
