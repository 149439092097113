<template>
	<div class="footer-content">
		<div @click.stop="addCart" class="add-cart"><img src="~@/assets/img-v3/new-playVideo/add-cart.gif"
				style="width: 120px;" /></div>

		<div v-for="(item, index) in info" :key="index">
			<p v-if="item.state == 1">
				<span>{{ item.label }}:</span>
				<span>{{ item.value }}</span>
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			info: {
				type: [Array, String],
				default: () => []
			}
		},
		methods: {
			addCart() {
				this.$emit('addCart');
			}
		}
	};

</script>

<style lang="less" scoped>
	.footer-content {
		position: relative;

		.add-cart {
			position: absolute;
			bottom: 120px;
		}

		p {
			display: flex;
			flex-direction: row;
			margin-top: 3px;
			color: #fff;

			span:first-child {
				width: 37px;
				color: #fff;
			}

			span:last-child {
				width: 160px;
				color: #fff;
			}
		}
	}

</style>

